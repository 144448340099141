<template>
  <div class="find-us">
    <p class="title">Find Us</p>
    <div class="columns">
      <div class="column map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5299.660052203669!2d-89.30666482324337!3d48.383001334042994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4d591fecf533a4f3%3A0x8b7b023a523cb470!2s705%20Century%20Ct%2C%20Thunder%20Bay%2C%20ON%20P7E%203N3!5e0!3m2!1sen!2sca!4v1727285649346!5m2!1sen!2sca" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <div class="column">
        <address>
          <strong>North Maple Child & Family Centre</strong><br>
          705 Century Ct<br>
          Thunder Bay, ON P7E 3N3<br>
          Canada<br>
        </address>
        <div class="contact">
          <div class="phonenumber">
            <i class="fas fa-phone"></i>
            807-123-4567<br>
          </div>
          <div class="email">
            <i class="far fa-envelope"></i>
           info@northmaplecfc.ca<br>
          </div>
          <div class="hours">
            <i class="far fa-calendar-alt"></i>
            Monday - Friday<br>
            <i class="far fa-clock"></i>
            7:00am - 5:00pm<br>
          </div>
        </div>
        <div class="directors">
          <h4><u>Directors</u></h4>
          <p>Ashley Hymers - ahymers@northmaplecfc.ca</p>
          <p>Tracey Dowling - tdowling@northmaplecfc.ca</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FindUs',
};
</script>

<style>
.contact {
  margin-top: 1rem;
}
address {
  font-size: larger;
}
.directors {
  margin-top: 1rem;
  line-height: 0.5;
}
</style>
