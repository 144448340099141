<template>
  <div class="program-content">
    <h1>Program Page</h1>
  </div>
</template>

<script>
export default {
  name: 'ProgramContent',
};
</script>

<style></style>
