<template>
  <div class="mainapp">
    <div class="header" id="header">
      <div class="logo">
        <img src="./assets/Logo_transparent.png" alt="Large Logo">
      </div>
      <div class="buttons is-responsive">
        <button @click="changeComponent('HomeContent')">Home</button>
        <button @click="changeComponent('AboutContent')">About Us</button>
        <button @click="changeComponent('ProgramContent')">Programs</button>
        <button @click="changeComponent('FindUs')">Find Us</button>
      </div>
    </div>
    <div class="content">
      <component :is="currentComponent" />
    </div>
    <footer-content />
  </div>
</template>

<script>
import HomeContent from './components/HomeContent.vue';
import ProgramContent from './components/ProgramContent.vue';
import AboutContent from './components/AboutContent.vue';
import FindUs from './components/FindUs.vue';
import FooterContent from './components/FooterContent.vue';

export default {
  name: 'App',
  components: {
    HomeContent,
    ProgramContent,
    AboutContent,
    FindUs,
    FooterContent
  },
  data() {
    return {
      currentComponent: 'HomeContent'
    }
  },
  methods: {
    changeComponent(component) {
      this.currentComponent = component;
    }
  },
  mounted() {
    window.onscroll = function() {
    const header = document.getElementById("header");
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        header.classList.add("shrink");
    } else {
        header.classList.remove("shrink");
    }
};
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&display=swap');
body {
  background-color: white;
}
#app {
  font-family: "Assistant", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.header {
  background-color: white;
  
  padding: 20px;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 0;
  transition: all 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column; /* Stack logo and buttons vertically */
  align-items: center; /* Center items horizontally */
}

.header.shrink {

    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.logo img {
    width: 40rem; /* Large logo size */
    transition: width 0.3s ease;
}

.header.shrink .logo img {
    width: 10rem; /* Small logo size */
}

.buttons {
    display: flex; /* Use flexbox for buttons */
    justify-content: center; /* Center buttons horizontally */
    flex-wrap: wrap; /* Allow buttons to wrap if necessary */
}

.buttons button {
    margin: 5px;
    padding: 10px 20px;
}

.buttons button {
    align-items: center;
    background-color: #FFE7E7;
    background-position: 0 0;
    border: 1px solid #FEE0E0;
    border-radius: 11px;
    box-sizing: border-box;
    color: #D33A2C;
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    font-weight: 700;
    line-height: 33.4929px;
    text-align: left;
    text-decoration: none;
    text-shadow: none;
    text-underline-offset: 1px;
    transition: border .2s ease-in-out, box-shadow .2s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    word-break: break-word;
}

.buttons button:hover {
    background-color: #FFC4C4;
    border-color: #FFA7A7;
    transform: scale(1.15);
}

.header.shrink .buttons button {
    font-size: 14px;
    padding: 0 10px;
}
@media screen and (max-width: 768px) {
  .header {
    padding: 1px;
    text-align: center;
    position: relative;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center; 
  }
  .buttons button {
    margin: 1px;
    padding: 1px 2px;
    width: 100%;
    font-size: 16px;
    text-align: center;
  }
  .header.shrink {
    padding: 1px;
    box-shadow: none;
  }
}
</style>