<template>
  <div class="about-content">
    <h1>About Page</h1>
  </div>
</template>

<script>
export default {
  name: 'AboutContent',
};
</script>

<style></style>
