<template>
  <footer class="footer-content footer columns">
    <div class="column" id="foot1">
      <address>
        North Maple Child & Family Centre<br>
        1234 North Maple Rd<br>
        North Maple, ON<br>
        L6A 2K3
      </address>
    </div>
    <div class="column" id="foot2">
      <div class="contact">
        <i class="fas fa-phone"></i>
        807-123-4567<br>
        <i class="far fa-envelope"></i>
        northmaple@gmail.com<br>
        <i class="far fa-calendar-alt"></i>
        Monday - Friday<br>
        <i class="far fa-clock"></i>
        7:00am - 5:00pm<br>
      </div>
    </div>
    <div class="column" id="foot3">
      <h3>Connect with us!</h3>
      <div class="fb-page" data-href="https://www.facebook.com/SesameStreet" data-tabs="timeline" data-width="400" data-height="400" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="false"></div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterContent',
};
</script>

<style scoped>
footer {
    background-color: #d77a00;
    color: white;
    vertical-align: top;
}

.columns {
  justify-content: space-around;
  align-items: top;
  padding: 0;
}

.columns .column {
  margin: 0.75rem 1rem;
  font-size: larger;
}

#foot2 .contact {
  text-align: left;
  width: 60%;
  margin: 0 auto;
}

@media screen and (max-width: 1240px) {
  .columns .column {
      font-size: medium;
  }
  #foot2 .contact {
  width: 90%;
  margin: 0 auto;
}
}
@media screen and (max-width: 928px) {
  .columns .column {
      font-size: small;
  }
}
@media screen and (max-width: 768px) {
  .columns .column {
      font-size: large;
  }
  #foot1,
  #foot2 .contact {
    text-align: center;
  }
}
</style>
