<template>
  <div class="home-content">
    <div class="content">
      <img src="../assets/toddlers2_png.png" alt="" srcset="">
      <section class="infotext">
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sequi voluptatem laudantium, debitis dolorum numquam nihil recusandae tempore modi, ad alias sint tempora earum praesentium voluptas illum, explicabo similique at omnis sit quae placeat repudiandae officia harum? Vero distinctio, ad sunt quos dolores nam dolor dolorem corrupti explicabo laboriosam culpa repellat?</p>
      </section>
      <p style="margin-bottom: 200px;"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeContent',
};
</script>

<style>
.content {
    margin-top: 15rem;
    width: 100%;
    transition: margin-top 0.3s ease;
    background-color: white;
    font-size: larger;
}

.content img {
    width: 100%;
    height: auto;
}

section.infotext {
    margin: 1.2rem 10rem;
    text-align: center;
}

@media screen and (max-width: 768px) {
    section.infotext {
        margin: 0.5rem 1rem;
    }
    .content {
      margin-top: 5rem;
      width: 100%;
      background-color: white;
    }
}
</style>
